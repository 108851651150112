import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79e00843"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "dashboard"
}
const _hoisted_3 = {
  key: 0,
  class: "persons"
}
const _hoisted_4 = { class: "dashbord-info" }
const _hoisted_5 = { class: "person-container" }
const _hoisted_6 = {
  key: 1,
  class: "dashboard"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_PersonCard = _resolveComponent("PersonCard")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_StatusModal = _resolveComponent("StatusModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SiteHeader, { onAddedPerson: _ctx.getPersons }, null, 8, ["onAddedPerson"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.persons.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("dashboard.welcomeLabel")), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("dashboard.descriptionLabel")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.persons, (person) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "person-card-parent",
                        style: {"display":"inline-block"},
                        key: person.userId
                      }, [
                        _createVNode(_component_PersonCard, {
                          person: person,
                          onRemovePerson: _ctx.getPersons,
                          onDeceasePerson: _ctx.getPersons
                        }, null, 8, ["person", "onRemovePerson", "onDeceasePerson"])
                      ]))
                    }), 128))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.$t("dashboard.noDataLabel")), 1),
                  _createElementVNode("button", {
                    id: "invite-code-button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInviteModal = true))
                  }, _toDisplayString(_ctx.$t("dashboard.acceptInviteButtonLabel")), 1)
                ]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_SiteFooter),
    (_ctx.showInviteModal)
      ? (_openBlock(), _createBlock(_component_ConfirmModal, {
          key: 0,
          title: _ctx.$t('dashboard.modalTitle'),
          message: _ctx.$t('dashboard.modalMessage'),
          invite: true,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showInviteModal = false)),
          onConfirm: _ctx.handleInviteCode
        }, null, 8, ["title", "message", "onConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.showStatusModal)
      ? (_openBlock(), _createBlock(_component_StatusModal, {
          key: 1,
          title: _ctx.statusTitleText,
          message: _ctx.statusModalText,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showStatusModal = false))
        }, null, 8, ["title", "message"]))
      : _createCommentVNode("", true)
  ], 64))
}