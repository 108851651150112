<template>
  <nav v-if="false">
    <!-- hide menu -->
    <router-link to="/">Dashboard</router-link> |
    <router-link to="/login">Login</router-link>
  </nav>
  <router-view />
</template>

<style lang="scss">
@import "./assets/scss/_colors.scss";

#app {
  font-family: "Futura EF Book", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  color: $primary-color;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  white-space: pre-line; // for \n in json translation files
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: $secondary-color;
}

nav a.router-link-exact-active {
  color: $accent-color;
}
</style>
