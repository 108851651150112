
import { defineComponent } from "vue";
import { TrustserviceAPI } from "@/api/api";
import router from "@/router";
import LanguageSwitcher from "./LanguageSwitcher.vue";

const trustServiceAPI = new TrustserviceAPI();

export default defineComponent({
  name: "SiteHeader",
  data() {
    return {
      showInviteModal: false,
      hover: false,
      userToken: localStorage.token,
    };
  },

  methods: {
    async handleLogout() {
      await trustServiceAPI.logout();
      router.push("/login");
    },
  },

  components: { LanguageSwitcher },
});
