
import { defineComponent } from "vue";
import { Person } from "@/types";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { TrustserviceAPI } from "@/api/api";

const trustServiceAPI = new TrustserviceAPI();

export default defineComponent({
  name: "PersonCard",
  props: {
    person: {
      type: Object as () => Person,
      required: true,
    },
  },
  methods: {
    async confirmDeath(id: string) {
      this.showDeathModal = false;
      await trustServiceAPI.manageUser(id, "decease");
      this.$emit("deceasePerson");
    },
    async removePerson(id: string) {
      this.showRemoveModal = false;
      await trustServiceAPI.manageUser(id, "remove");
      this.$emit("removePerson");
    },
  },
  computed: {
    deceaseButtonText() {
      return this.person.deceased === "true"
        ? this.$t("personCard.deathConfirmedButtonLabel")
        : this.$t("personCard.deathButtonLabel");
    },
  },
  components: { ConfirmModal },
  data: () => ({
    showDeathModal: false,
    showRemoveModal: false,
  }),
});
