import {
  RequestOTPResponse,
  UsersResponse,
  LoginResponse,
  OTPRequestBody,
  LoginRequestBody,
  LogoutResponse,
  InvitationAction,
  AcceptInvitationRequestBody,
  ManageUserAction,
  ManageUserRequestBody,
} from "@/types";

export class TrustserviceAPI {
  private _baseUrl: string;
  private _apiKey: string;
  constructor() {
    this._baseUrl = process.env.VUE_APP_BASE_URL;
    this._apiKey = process.env.VUE_APP_API_KEY;
  }

  public async requestLoginOTP(email: string): Promise<RequestOTPResponse> {
    const requestBody = {
      email: email,
    } as OTPRequestBody;
    const response = await fetch(this._baseUrl + "/send-login-otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    return await responseBody;
  }

  public async login(email: string, otp: string): Promise<LoginResponse> {
    const requestBody = {
      email: email,
      OTP: otp,
      deviceType: "web",
    } as LoginRequestBody;
    const response = await fetch(this._baseUrl + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    try {
      localStorage.token = await responseBody.data.token;
    } catch (e) {
      localStorage.token = "";
    }
    return await responseBody;
  }

  public async logout(): Promise<LogoutResponse> {
    const response = await fetch(this._baseUrl + "/logout", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `${localStorage.token}`,
      },
    });
    localStorage.removeItem("token");
    const responseBody = await response.json();
    return await responseBody;
  }

  public async getUsers(): Promise<UsersResponse> {
    const response = await fetch(this._baseUrl + "/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `${localStorage.token}`,
      },
    });
    return await response.json();
  }

  public async acceptInvitation(inviteCode: string, action: InvitationAction) {
    const requestBody = {
      inviteCode: inviteCode,
      action: action,
    } as AcceptInvitationRequestBody;
    const response = await fetch(this._baseUrl + "/invitation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `${localStorage.token}`,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  }

  public async manageUser(userId: string, action: ManageUserAction) {
    const requestBody = {
      userId: userId,
      action: action,
    } as ManageUserRequestBody;

    const response = await fetch(this._baseUrl + "/manageuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `${localStorage.token}`,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  }
}
