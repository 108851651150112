
import { defineComponent } from "vue";
import PersonCard from "@/components/PersonCard.vue";
import SiteHeader from "@/components/SiteHeader.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { AcceptInvitationResponse, Person, UsersResponse, UsersResponseData } from "@/types";
import { useI18n } from "vue-i18n";

import { TrustserviceAPI } from "@/api/api";
import router from "@/router";
import StatusModal from "@/components/statusModal.vue";
const trustServiceAPI = new TrustserviceAPI();
export default defineComponent({
  name: "DashboardView",
  components: {
    PersonCard,
    SiteHeader,
    SiteFooter,
    ConfirmModal,
    StatusModal,
  },
  setup() {
    const t = useI18n();
    return { t };
  },
  data() {
    return {
      persons: [] as Array<Person>,
      noPersons: true,
      showContent: false,
      showInviteModal: false,
      showStatusModal: false,
      inviteCode: "",
      statusTitleText: "",
      statusModalText: "",
    };
  },
  created() {
    if (this.$route.query.invitationCode) {
      this.getInviteCodeParam();
    } else if (localStorage.getItem("invitationCode")) {
      this.getInviteCodefromStore();
    } else {
      this.getPersons();
    }

    if (this.$route.query.language) {
      this.t.locale.value = this.$route.query.language as string;
    }
  },

  methods: {
    async getInviteCodefromStore() {
      this.inviteCode = localStorage.getItem("invitationCode") as string;
      if (this.inviteCode !== undefined) await this.handleInviteCode(this.inviteCode as string);
    },

    async getInviteCodeParam() {
      this.inviteCode = this.$route.query.invitationCode as string;
      if (this.inviteCode !== undefined) localStorage.setItem("invitationCode", this.inviteCode);
      if (!localStorage.getItem("token")) {
        router.push("/login");
      } else {
        this.getInviteCodefromStore();
      }
    },

    async getPersons() {
      try {
        if (localStorage.getItem("token")) {
          const res = (await trustServiceAPI.getUsers()) as UsersResponse;
          if (res.status === "success") {
            this.persons = this.mapUsersResponseDataToPerson(res);
            this.showContent = true;
          } else {
            router.push("/login");
          }
        } else {
          router.push("/login");
        }
      } catch (err) {
        console.error(err);
      }
    },
    mapUsersResponseDataToPerson(usersResponse: UsersResponse): Array<Person> {
      return usersResponse.data.map((userData: UsersResponseData) => {
        const { UserId, Name, ProfilePic, IsDeceaseSend } = userData;
        return {
          userId: UserId,
          name: Name,
          profilePic: ProfilePic,
          deceased: IsDeceaseSend,
        };
      });
    },
    async handleInviteCode(inviteCode: string) {
      const res = await trustServiceAPI.acceptInvitation(inviteCode, "accept");
      this.handleResMessage(res);
      this.showInviteModal = false;
      this.getPersons();
      if (res.status === "success") {
        localStorage.removeItem("invitationCode");
      }
    },
    async handleResMessage(res: AcceptInvitationResponse) {
      if (res.status === "success") {
        this.statusTitleText = this.$t("infoModal.successTitle") as string;
        this.statusModalText = this.$t("infoModal.inviteSuccessMessage") as string;
        this.showStatusModal = true;
      } else if (res.status === "failed") {
        this.statusTitleText = this.$t("infoModal.errorTitle") as string;
        switch (res.message) {
          case "Invalid OTP.":
            this.statusModalText = this.$t("infoModal.inviteErrorMessage") as string;
            localStorage.removeItem("invitationCode");
            break;
          case "Something wrong":
            this.statusModalText = this.$t("infoModal.genericErrorMessage") as string;
            break;
          default:
            this.statusModalText = this.$t("infoModal.genericErrorMessage") as string;
            break;
        }
        this.showStatusModal = true;
      } else {
        this.showStatusModal = false;
      }
      router.replace("/");
    },
  },
});
