import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b019a7fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "close-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-xmark" })
              ]),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
                ], true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "body", {}, () => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
                ], true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                  }, "OK")
                ], true)
              ])
            ])
          ])
        ])
      ]),
      _: 3
    })
  ]))
}