<template>
  <div class="locale-changer">
    <font-awesome-icon icon="fa-solid fa-globe" />
    <select v-model="$i18n.locale" class="lang-select">
      <option value="de" selected>DE</option>
      <option value="en">EN</option>
    </select>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
@import "../assets/scss/_typography.scss";

.lang-select {
  border: none;
  font-family: Futura EF Book;
  font-size: $body-text-font-size;
  color: $primary-color;
  background-color: transparent;
}

.locale-changer svg {
  color: $primary-color;
  margin-right: 10px;
  height: auto;
  width: 18px;
}

.lang-select:focus {
  outline: none;
}

.lang-select:hover {
  cursor: pointer;
  color: $accent-color;
}
</style>
