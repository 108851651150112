
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmModal",
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    invite: {
      type: Boolean,
      required: false,
    },
    death: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      inviteCode: "",
    };
  },
  computed: {
    confirmButtonText() {
      if (this.invite) {
        return this.$t("modal.confirmInviteButtonLabel");
      } else {
        return this.$t("modal.confirmButtonLabel");
      }
    },

    cancelButtonText() {
      if (this.invite) {
        return this.$t("modal.cancelButtonLabel");
      } else {
        return this.$t("modal.noButtonLabel");
      }
    },
  },
});
