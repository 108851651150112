import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import "./assets/scss/main.scss";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import { faArrowRightFromBracket, faEnvelopeOpenText, faXmark, faGlobe } from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(faArrowRightFromBracket, faEnvelopeOpenText, faXmark, faGlobe);

createApp(App).use(router).use(i18n).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
